import React, { useContext, useMemo } from 'react'
import { Avatar, MenuPopover, Icon, Tooltip } from '@mondra/ui-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'contexts/UserContextProvider'

export function UserAvatar() {
  const { logout } = useAuth0()
  const { email, fullName } = useContext(UserContext)
  const { t } = useTranslation(['common'])
  const options = useMemo(() => {
    return [
      {
        id: 'logout',
        onClick: () => {
          logout({ returnTo: window.location.origin })
        },
        value: t('logout'),
      },
    ]
  }, [logout, t])

  return (
    <MenuPopover
      className=""
      triggerButtonClass="group h-14 transition-all"
      // showBorder
      menuItems={options}
      trigger={
        <Tooltip interactive content={email} placement="bottom-end">
          <div className="transition-all flex items-center space-x-2 text-primary-600 group-hover:text-primary-900">
            <Avatar
              className="!text-base"
              name={fullName}
              size="sm"
              bgColor="bg-primary-300"
              textColor="text-white"
              singleChar
            />
            <div className="font-medium group-hover:text-primary-600">{fullName}</div>
            <Icon type="chevronDown" size={20} className="text-current" />
          </div>
        </Tooltip>
      }
    />
  )
}
