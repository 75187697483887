import React, { useContext, useMemo } from 'react'
import { Header, Link, NavTabs } from '@mondra/ui-components'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { CompanySelectDialog } from 'pages/layout/CompanySelectDialog'
import { APP_NAV_URLS } from 'constants/appNavUrls'
import { CompanyContext } from 'contexts/CompanyContextProvider'

import { UserAvatar } from './user-avatar/UserAvatar'
import { LanguagesMenu } from './languages-menu/LanguagesMenu'
import { HelpIcon } from './HelpIcon'
import { resolveUrl } from 'utils/resolveUrl'
import { UserContext } from 'contexts/UserContextProvider'

export function AppHeader() {
  const { t } = useTranslation(['common'])
  const { company } = useContext(CompanyContext)
  const { is3KeelUser, isSurveyWindowClosed } = useContext(UserContext)

  const navLinks = useMemo(
    () =>
      is3KeelUser
        ? [
            {
              label: t('surveys'),
              to: resolveUrl(APP_NAV_URLS.ADMIN.BASE),
            },
          ]
        : company.id
        ? [
            {
              label: t('archivedSurveys'),
              to: resolveUrl(APP_NAV_URLS.ARCHIVED_SURVEYS, {}, { c: company.id }),
            },
            {
              label: t('surveys'),
              to: resolveUrl(APP_NAV_URLS.DASHBOARD, {}, { c: company.id }),
            },
          ]
        : [],
    [company.id, is3KeelUser, t],
  )

  return (
    <Header
      className="!bg-white !p-0 !border-b border-coolGray-200"
      left={
        <div className="flex h-full items-center justify-between">
          <Link className="!no-underline" as={NavLink} to={resolveUrl(APP_NAV_URLS.BASE, undefined, { c: company.id })}>
            <div className="flex items-center justify-between px-4 space-x-2">
              <img
                src="https://internalbranding.blob.core.windows.net/logos/3keel-primary.svg"
                alt="3Keel logo"
                className="h-10 w-[72px]"
              />
              <div className="text-base text-primary-600 font-bold ">Sustainable Commodities</div>
            </div>
          </Link>
          {!is3KeelUser && !isSurveyWindowClosed && <CompanySelectDialog />}
        </div>
      }
      right={
        <div className="flex h-full items-center justify-between">
          {!is3KeelUser && <LanguagesMenu />}
          <HelpIcon />
          <UserAvatar />
        </div>
      }
    >
      <div className="flex flex-1 justify-end">
        <NavTabs options={navLinks} gapClass="gap-0" />
      </div>
    </Header>
  )
}
