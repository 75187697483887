import { useContext, useMemo } from 'react'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSWRWithToken } from './useSWRWithToken'
import { DEFAULT_YEAR } from 'constants/'
import { DeclarationEnum, ISurveyFileContent, SurveyTypeEnum } from 'types'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import { StringType } from './types'

export interface IDeclaration {
  companyId: string
  type: SurveyTypeEnum
  declarationType: DeclarationEnum
  year: string | number
  declarations: ISurveyFileContent[]
  evidences: ISurveyFileContent[]
  originEvidences: ISurveyFileContent[]
}

const defaultData = []

export function useDeclarations(supplierId?: StringType, year = DEFAULT_YEAR) {
  const is3KeelUser = useContext(CompanyContext)
  const companyId = useCompanyId()

  const url = useMemo(
    () =>
      is3KeelUser && supplierId
        ? resolveSwrUrl(
            API_URLS.ADMIN.DECLARATIONS,
            {
              supplierId,
            },
            {
              year,
            },
          )
        : companyId
        ? resolveSwrUrl(
            API_URLS.DECLARATIONS,
            {
              companyId,
            },
            {
              year,
            },
          )
        : null,
    [companyId, is3KeelUser, supplierId, year],
  )

  const { data, error, isLoading, mutate } = useSWRWithToken<IDeclaration[]>(url, companyId)

  const refetch = () => {
    if (url) {
      mutate()
    }
  }

  return {
    data: data || defaultData,
    error,
    isLoading,
    refetch,
  }
}
