import useSWR, { SWRConfiguration } from 'swr'
import { useToken } from 'hooks/useToken'
import { useToastNotification } from '@mondra/ui-components'
import { TOAST_POSITION } from 'constants/'
import { fetchWithToken } from './fetchWithToken'

export function useSWRWithToken<T = any>(
  url?: string | null,
  companyId?: string,
  options?: SWRConfiguration
) {
  const { acquireToken } = useToken()
  const { showError } = useToastNotification(TOAST_POSITION)
  return useSWR<T>(
    url || null,
    apiUrl => fetchWithToken({ acquireToken, apiUrl, companyId, onError: showError }),
    options
  )
}
