import React, { useEffect } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { SWRConfig, SWRConfiguration } from 'swr'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { ErrorPage } from './pages/error-page/ErrorPage'
import CompanyContextProvider from 'contexts/CompanyContextProvider'
import { LogoLoader } from 'components/skeleton-loaders/LogoLoader'
import ErrorBoundary from 'components/ErrorBoundary'
import { COMPANY_ID_SEARCH_PARAM } from 'constants/'

import { SurveyParamsType } from 'types'

import { PendoTracker } from 'components/PendoTracker'
import { useLoginRedirect } from 'hooks/useLoginRedirect'
import { AppHeader } from 'components/AppHeader'
import { UserProvider } from 'contexts/UserContextProvider'
import { useByPassClosure } from 'hooks/useByPassClosure'
import { YearContextProvider } from './contexts/YearContextProvider'
import SurveysStatsContextProvider from './contexts/SurveysStatsContextProvider'

const swrConfig: SWRConfiguration = { revalidateOnFocus: false, shouldRetryOnError: false }

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0()
  const triggerLogin = useLoginRedirect()
  const [searchParams] = useSearchParams()
  const { surveyId, type } = useParams<SurveyParamsType>()
  const companyId = searchParams.get(COMPANY_ID_SEARCH_PARAM)

  useByPassClosure() // use this hook to set bypass flag even before login

  useEffect(() => {
    if (!isLoading && !isAuthenticated && isEmpty(error)) {
      triggerLogin({
        companyId,
        surveyId,
        type,
      })
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, error, surveyId, companyId, type])

  if (!isEmpty(error)) {
    return <ErrorPage deafaultErrorMsg={error instanceof Error ? error.message : error} />
  }

  if (!isAuthenticated) {
    return <LogoLoader />
  }

  return (
    <SWRConfig value={swrConfig}>
      <UserProvider>
        <CompanyContextProvider isAuthenticating={isLoading || !isAuthenticated}>
          <YearContextProvider>
            <SurveysStatsContextProvider>
              <PendoTracker />
              <div className="flex flex-col h-screen bg-coolGray-50">
                <AppHeader />
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              </div>
            </SurveysStatsContextProvider>
          </YearContextProvider>
        </CompanyContextProvider>
      </UserProvider>
    </SWRConfig>
  )
}

export default App
