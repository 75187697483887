import { SurveyTypeEnum } from 'types'
import { IUseToastNotification } from '@mondra/ui-components'

export const COMPANY_ID_SEARCH_PARAM = 'c'
export const SUPPLIER_ID_SEARCH_PARAM = 's'

export const USER_METADATA = 'https://user_metadata'

export const DEFAULT_YEAR = Number(process.env.REACT_APP_CURRENT_YEAR)
export const PAST_YEAR = DEFAULT_YEAR - 1

export const START_YEAR = Number(process.env.REACT_APP_START_YEAR)

export const REQUEST_ACCESS_DEFAULT_COMPANY = '11111111-1111-1111-1111-111111111111'
export const OFFLINE_DECLARATION_NAVIGATION = 'offlineDeclaration'

export const EMPTY_COMPANY = {
  city: null,
  companyLogoUrl: null,
  companySize: null,
  country: null,
  description: null,
  id: '',
  isPublic: true,
  name: null,
  postCode: null,
  registrationNumber: null,
  sharePointPath: null,
  ssoConnection: null,
  state: null,
  street: null,
  type: null,
  website: null,
  zohoCrmAccountId: 0,
}

export const PAGE_SIZE = 25
export const GRID_ROW_HEIGHT = 48

export const DOWNSHIT_CHANGE_TYPE = 6
export const DOWNSHIT_ITEM_SELECT_TYPE = 10
export const DOWNSHIT_ITEM_CLEAR_TYPE = 16

export const COUNTRY_OPTIONS_FIELDS = ['country', 'rearingLiveStockCountry']

export const TEMPLATE_DEFAULT_VALUE = {
  name: 'Temlate',
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const CURRENT_COMMODITIES = [
  SurveyTypeEnum.SOYA,
  SurveyTypeEnum.PALMOIL,
  SurveyTypeEnum.COCOA,
  SurveyTypeEnum.COFFEE,
]

export const SUPPORT_EMAIL = 'support.3keel@mondra.com'

export const DOWNLOAD_BUTTON_ATTRIBUTE_COMMODITY = 'data-mondra-commodity'
export const DOWNLOAD_BUTTON_ATTRIBUTE_LNG = 'data-mondra-lng'
export const DOWNLOAD_BUTTON_ATTRIBUTE_QUESTION_TYPE = 'data-mondra-question-type'
export const DOWNLOAD_BUTTON_ATTRIBUTE_DECLARATION_TYPE = 'data-mondra-declaration-type'
export const DOWNLOAD_BUTTON_ATTRIBUTE_NAVIGATE = 'data-mondra-navigate'
export const DOWNLOAD_BUTTON_ATTRIBUTE_YEAR = 'data-mondra-year'

export const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
export const COMMODITY_APP = 'Commodity'

export const toastOptions = {
  error: {
    duration: 20000,
  },
  info: {
    duration: 20000,
  },
}

export const BYPASS_SEARCH_PARAM = 'unlock'
export const BYPASS_SEARCH_PARAM_VALUE = process.env.REACT_APP_BYPASS_CLOSURE_CODE
export const BYPASS_SESS_KEY = 'CommodityByPass'

export const DEFAULT_GRID_CONTEXT = { isArchive: true }

export const TOAST_POSITION: IUseToastNotification = {
  position: 'top-center',
}
