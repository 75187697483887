import React, { createContext, ReactNode, useState } from 'react'
import { DEFAULT_YEAR, PAST_YEAR, START_YEAR } from '../constants'

interface IYearContextProps {
  selectedYear: string
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>
  pastYearsSelection: Array<{ id: string; value: string }>
  allYearsSelection: Array<{ id: string; value: string }>
}

const pastYearsSelection = Array.from({ length: DEFAULT_YEAR - START_YEAR }, (_, index) => ({
  id: (START_YEAR + index).toString(),
  value: (START_YEAR + index).toString(),
}))

const allYearsSelection = Array.from({ length: DEFAULT_YEAR - START_YEAR + 1 }, (_, index) => ({
  id: (START_YEAR + index).toString(),
  value: (START_YEAR + index).toString(),
}))

const defaultContextProps: IYearContextProps = {
  selectedYear: String(PAST_YEAR),
  setSelectedYear: () => {},
  pastYearsSelection: pastYearsSelection,
  allYearsSelection: allYearsSelection,
}

export const YearContext = createContext<IYearContextProps>(defaultContextProps)

export const YearContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedYear, setSelectedYear] = useState<string>(String(PAST_YEAR))

  return (
    <YearContext.Provider
      value={{ selectedYear, setSelectedYear, pastYearsSelection, allYearsSelection }}
    >
      {children}
    </YearContext.Provider>
  )
}
