import { useCallback, useContext, useMemo } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import { useCompanyId } from 'hooks/useCompanyId'
import { UserContext } from 'contexts/UserContextProvider'
import { useSWRWithToken } from './useSWRWithToken'
import { ISurveysData } from './types'

const defaultSurveys = {
  year: '',
  companyId: '',
  surveys: [],
  retailers: [],
}

export function useSurveys(year: string | number) {
  const { is3KeelUser } = useContext(UserContext)

  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      !is3KeelUser && companyId
        ? resolveSwrUrl(
            API_URLS.SURVEYS,
            {
              companyId,
            },
            {
              year,
            },
          )
        : null,
    [companyId, is3KeelUser],
  )

  const { data, error, isLoading, mutate } = useSWRWithToken<ISurveysData>(url, companyId)

  const refetch = useCallback(() => {
    if (url) {
      mutate()
    }
  }, [url])

  return {
    data: data || defaultSurveys,
    error,
    isLoading: is3KeelUser ? false : isLoading || isEmpty(url),
    refetch,
  }
}
