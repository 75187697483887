import { useToastNotification } from '@mondra/ui-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'
import { useToken } from '../hooks/useToken'
import { IErrorResponse, MutateError, StringType, TMutateError } from './types'
import { DEFAULT_SERVER_ERROR_LABEL, DEFAULT_SERVER_ERROR_MESSAGE } from './constants'
import isEmpty from 'lodash/fp/isEmpty'
import { TOAST_POSITION, toastOptions } from 'constants/'

const enum HttpMethodsEnum {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

type HttpHeadersType = {
  [key: string]: string
}

interface IFetcher {
  arg: {
    payload: unknown
    token: string | undefined
    method: HttpMethodsEnum
    headers: HttpHeadersType
  }
}

interface IFetcherOptions {
  headers: HttpHeadersType
  body?: string
  method: HttpMethodsEnum
}

async function fetcher(url: string, { arg }: IFetcher) {
  const { payload, token, method, headers } = arg

  const options: IFetcherOptions = {
    headers: {
      // Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/json',
      'X-MONDRA-APP': 'Commodities',
      ...headers,
    },
    method,
  }

  if (method !== HttpMethodsEnum.GET && headers['Content-Type'] === 'application/json') {
    options.body = JSON.stringify(payload)
  } else if (method !== HttpMethodsEnum.GET) {
    options.body = payload as any
  }

  const res = await fetch(url, options)

  const text = await res.text()

  let response

  try {
    const data = JSON.parse(text)
    response = data
  } catch (err) {
    response = text
  }

  if (!res.ok) {
    return Promise.reject(
      new MutateError(
        typeof response === 'string' || isEmpty(response)
          ? {
              detail: DEFAULT_SERVER_ERROR_MESSAGE,
              message: DEFAULT_SERVER_ERROR_LABEL,
              status: res.status,
              traceId: null,
            }
          : response,
      ),
    )
  }

  return response
}

interface IuseMutateProps {
  url: StringType
  companyId?: string
  errorMessage?: StringType
  headers?: HttpHeadersType
  onError?: (error: Partial<IErrorResponse>) => void
  options?: any
  successMessage?: StringType
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}
const DEFAULT_MUTATE_OPTIONS = {
  revalidate: false,
}

export function useMutate<T>({
  companyId,
  errorMessage,
  headers = defaultHeaders,
  onError,
  options = DEFAULT_MUTATE_OPTIONS,
  successMessage,
  url,
}: IuseMutateProps) {
  const { acquireToken } = useToken()
  const { t } = useTranslation(['successMessages', 'errorMessages'])
  const { showError, showSuccess } = useToastNotification(TOAST_POSITION)

  const defaultOptions = {
    onError: (error: TMutateError) => {
      const { response } = error
      if (onError) {
        onError(response)
      } else {
        showError({
          description: response.code ? t(`errorMessages:${response.code}`) : t('errorMessages:error'),
          label: response?.status?.toString(),
          children: errorMessage ? `${errorMessage} - ${response.detail}` : response.detail,
        }, { duration: 5000 })
      }
    },
    onSuccess: () => {
      if (successMessage) {
        showSuccess({
          description: successMessage,
          label: t('successMessages:success'),
        })
      }
    },
  }

  const { trigger: swrTrigger, ...rest } = useSWRMutation(url, fetcher, {
    ...defaultOptions,
    ...options,
  })

  const post = useCallback(
    async (payload = {}) => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.POST, payload, token, headers })
    },
    [acquireToken, swrTrigger, companyId, headers],
  )

  const put = useCallback(
    async (payload = {}) => {
      const token = await acquireToken(companyId)
      try {
        return swrTrigger<T>({ method: HttpMethodsEnum.PUT, payload, token, headers })
      } catch (error) {
        console.error(error)
      }
    },
    [acquireToken, swrTrigger, companyId, headers],
  )

  const remove = useCallback(
    async (payload = {}) => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.DELETE, payload, token, headers })
    },
    [acquireToken, swrTrigger, companyId, headers],
  )

  const get = useCallback(
    async (payload = {}) => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.GET, payload, token, headers })
    },
    [acquireToken, swrTrigger, companyId, headers],
  )

  return {
    get,
    post,
    put,
    remove,
    ...rest,
  }
}
