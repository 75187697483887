import isEmpty from 'lodash/fp/isEmpty'
import { useAuth0 } from '@auth0/auth0-react'
import { API_URLS } from 'constants/apiUrls'
import { ZOHO_USER_TOKEN_ERROR } from 'constants/authErrors'
import { useMutate } from './useMutate'

export function useZohoAsapUserToken() {
  const { isAuthenticated } = useAuth0()
  const url = isAuthenticated ? API_URLS.ZOHO_ASAP_USER_TOKEN : null
  const {
    post: fetchZohoUserToken,
    data: zohoUserToken,
    error,
    isMutating,
  } = useMutate<string>({ url, errorMessage: ZOHO_USER_TOKEN_ERROR.TOKEN })
  return {
    error,
    fetchZohoUserToken,
    isLoading: isMutating || isEmpty(url),
    zohoUserToken,
  }
}
