import { SubmissionTypeEnum, SurveyStatusEnum, SurveyTypeEnum } from 'types'
import { DEFAULT_SERVER_ERROR_MESSAGE } from './constants'

export interface IErrorResponse {
  code: string,
  message: string
  detail: string
  traceId: string | null
  status: number
}


export interface IAuthError {
  error?: string
  error_description?: string
}

export class MutateError extends Error {
  response: Partial<IErrorResponse>

  constructor(r: Partial<IErrorResponse>, m: string = DEFAULT_SERVER_ERROR_MESSAGE) {
    super(m)

    this.response = r
  }
}

export type TMutateError = InstanceType<typeof MutateError>

export interface IMutateScenarioProduct {
  scenarioId: string
  productId: string
}


export type StringType = string | null | undefined

export interface ISurvey {
  id: string
  retailerName: string
  retailerId: string
  status: SurveyStatusEnum
  submissionType: SubmissionTypeEnum | null
  type: SurveyTypeEnum
  supplier?: string
}

export interface IRetailer {
  id: string
  name: string
}

export interface ISupplier {
  id: string
  name: string
}

export interface ISurveysData {
  companyId: string
  retailers: IRetailer[]
  surveys: ISurvey[]
  year: number
}