import { useMemo } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { SubmissionTypeEnum, SurveyTypeEnum } from 'types'
import { API_URLS } from '../constants/apiUrls'
import { resolveSwrUrl } from '../utils/resolveUrl'
import { useSWRWithToken } from './useSWRWithToken'

interface ISurveyResponseProps {
  type?: SurveyTypeEnum
  year?: string
}

export function useSurveyTemplates({ type, year }: ISurveyResponseProps) {
  const url = useMemo(
    () =>
      type && year
        ? resolveSwrUrl(API_URLS.SURVEY_TEMPLATE, {
            type,
            year,
          })
        : null,
    [type, year],
  )

  const { data, error, isLoading } = useSWRWithToken(url)
  return {
    data: data,
    error,
    isLoading: isLoading || isEmpty(url),
  }
}
