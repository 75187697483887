import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useSurveys } from '../api/useSurveys'
import { DEFAULT_YEAR } from '../constants'
import { SurveySectionStat, SurveyStatusEnum } from '../types'
import { ISurvey } from '../api/types'
import { IDeclaration, useDeclarations } from '../api/useDeclarations'
import { getDeclarationSurveys } from 'utils/contactConfirmation'

interface ISurveysStatsProps {
  isCustomerValidated: boolean
  validateCustomersStat: SurveySectionStat | null
  policyQuestionsStat: SurveySectionStat | null
  commodityDeclarationsStat: SurveySectionStat | null
  isLoading?: boolean
  refetchStats: () => void
}

const defaultContextProps = {
  isCustomerValidated: false,
  validateCustomersStat: null,
  policyQuestionsStat: null,
  commodityDeclarationsStat: null,
  refetchStats: () => null,
}

export const SurveysStatsContext = createContext<ISurveysStatsProps>(defaultContextProps)

interface ISurveysStatsContextProviderProps {
  children: React.ReactNode
}

export default function SurveysStatsContextProvider({
  children,
}: ISurveysStatsContextProviderProps) {
  const {
    data: { surveys },
    isLoading,
    refetch,
  } = useSurveys(DEFAULT_YEAR)
  const { data: declarations } = useDeclarations()

  const [validateCustomersStat, setValidateCustomersStat] = useState<SurveySectionStat | null>(null)
  const [policyQuestionsStat, setPolicyQuestionsStat] = useState<SurveySectionStat | null>(null)
  const [commodityDeclarationsStat, setCommodityDeclarationsStat] =
    useState<SurveySectionStat | null>(null)

  const isCustomerValidated = validateCustomersStat ? validateCustomersStat?.completed > 0 : false

  useEffect(() => {
    if (!isLoading) {
      setValidateCustomersStat(getValidateCustomersStat(surveys))
      setPolicyQuestionsStat(getPolicyQuestionsStat(surveys))
      setCommodityDeclarationsStat(getCommodityDeclarationsStat(surveys, declarations))
    } else {
      setValidateCustomersStat(null)
      setPolicyQuestionsStat(null)
      setCommodityDeclarationsStat(null)
    }
  }, [isLoading, surveys, declarations])

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <SurveysStatsContext.Provider
      value={{
        isCustomerValidated,
        validateCustomersStat,
        policyQuestionsStat,
        commodityDeclarationsStat,
        isLoading,
        refetchStats: refetch,
      }}
    >
      {children}
    </SurveysStatsContext.Provider>
  )
}

function getTotalSurveysCount(surveys: ISurvey[]) {
  return (
    surveys?.filter(
      survey =>
        survey.status !== SurveyStatusEnum.ExclusionRequested &&
        survey.status !== SurveyStatusEnum.Excluded,
    ).length || 0
  )
}

function getValidateCustomersStat(surveys: ISurvey[]): SurveySectionStat {
  const total = getTotalSurveysCount(surveys)
  const completed = surveys.filter(
    survey =>
      survey.status !== SurveyStatusEnum.Sent &&
      survey.status !== SurveyStatusEnum.Excluded &&
      survey.status !== SurveyStatusEnum.ExclusionRequested,
  ).length

  return { total, completed }
}

function getPolicyQuestionsStat(surveys: ISurvey[]) {
  const total = getTotalSurveysCount(surveys)
  const completed = surveys.filter(
    survey =>
      survey.status === SurveyStatusEnum.InReview ||
      survey.status === SurveyStatusEnum.Accepted ||
      survey.status === SurveyStatusEnum.Completed,
  ).length

  return { total, completed }
}

function getCommodityDeclarationsStat(surveys: ISurvey[], declarations: IDeclaration[]) {
  const total = getDeclarationSurveys(surveys)?.length | 0
  const completed = declarations.filter(item => !!item.declarations.length).length

  return { total, completed }
}
