import { useAuth0 } from '@auth0/auth0-react'
import { buildQueryString } from 'utils/queryString'
import { COMMODITY_APP } from 'constants/'
import { IString } from '@mondra/ui-components/@types'

interface ItriggerLoginProps {
  companyId: IString | null
  defaultUrl?: string
  surveyId?: IString
  type?: IString
}
export function useLoginRedirect() {
  const { loginWithRedirect } = useAuth0()
  function triggerLogin({ companyId, defaultUrl, surveyId, type }: ItriggerLoginProps) {
    const additionalParams =
      companyId || surveyId
        ? {
            surveyId,
            companyId,
            surveyType: type,
            screen_hint: 'signup',
            mondra_app: COMMODITY_APP,
          }
        : {
            mondra_app: COMMODITY_APP,
          }
    loginWithRedirect({
      appState: {
        appQueryParams:
          window.location.search ||
          `?${buildQueryString({
            surveyId,
            c: companyId,
            type,
          })}`,
        returnTo: defaultUrl || window.location.pathname,
      },
      ...additionalParams,
    })
  }
  return triggerLogin
}
