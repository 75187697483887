import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, Icon, SearchInput, TriggerButton } from '@mondra/ui-components'
import classNames from 'classnames'
import { ICompany } from '../../types/index'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import { SkeletonLoader } from 'components/skeleton-loaders/SkeletonLoader'
import { resolveUrl } from 'utils/resolveUrl'
import { APP_NAV_URLS } from 'constants/appNavUrls'

interface ICompanyNameProps {
  loading: boolean
  name: string
  trigger: boolean
}

function CompanyName({ loading, name, trigger }: ICompanyNameProps) {
  return (
    <div
      className={classNames(
        'flex items-center text-primary-600 group-hover:text-primary-900 font-semibold',
        trigger ? 'space-x-2' : 'px-6 text-sm',
      )}
    >
      {loading ? (
        <SkeletonLoader className="w-full h-6" bgColor="bg-primary-100" />
      ) : (
        <div className="truncate" title={name}>
          {name ? name : trigger ? 'Select a company' : 'No company found'}
        </div>
      )}
      {trigger && <Icon type="chevronDown" size={20} className="text-current" />}
    </div>
  )
}

export function CompanySelectDialog() {
  const {
    companies,
    isLoading,
    company: { name: companyName },
  } = useContext(CompanyContext)
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const [filteredCompanies, setFilteredCompanies] = useState(companies)

  const handleTriggerButton = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSelect = useCallback(
    (company: ICompany) => () => {
      handleClose()
      navigate(resolveUrl(APP_NAV_URLS.DASHBOARD, {}, { c: company.id }))
    },
    [handleClose],
  )

  const handleKeyDownSelect = useCallback(
    (company: ICompany) => e => {
      if (e.key === 'Enter') {
        handleSelect(company)
      }
    },
    [handleSelect],
  )

  const handleSearch = (term: string) => {
    const filteredList = companies?.filter(({ name }: ICompany) =>
      (name as string).toLowerCase().includes(term.toLowerCase()),
    )
    setFilteredCompanies(term.length ? filteredList : companies)
  }

  const noCompanies = companies.length === 0

  return (
    <>
      {noCompanies ? (
        <div className="flex items-center text-primary-600 group-hover:text-primary-900 font-semibold px-6 text-sm">
          Company Not available
        </div>
      ) : (
        <TriggerButton
          onClick={handleTriggerButton}
          className="group !border-l !border-l-coolGray-200 !border-b-0"
        >
          <CompanyName name={companyName!} trigger loading={isLoading} />
        </TriggerButton>
      )}
      <Dialog open={isOpen} onClose={handleClose}>
        <Dialog.Title>
          <div className="px-4 pb-6 pt-3">
            <div className="text-lg font-bold text-coolGray-800">Who are you working with?</div>
            <div className="text-sm text-coolGray-600">Select a company from the list below.</div>
          </div>
        </Dialog.Title>
        <Dialog.Description>
          <div className="align-center flex flex-1 justify-center">
            <div className="m-auto w-full max-w-[430px] rounded border border-gray-300 bg-white">
              <SearchInput
                placeholder="Search by company name"
                className="border-x-0 border-b border-t-0 border-b-gray-300"
                onSearch={handleSearch}
              />
              <ul role="listbox" className="h-[360px] overflow-y-auto">
                {filteredCompanies?.map((company: ICompany) => {
                  return (
                    <li
                      className="flex cursor-pointer justify-between border-b border-gray-300 px-4 py-3.5 transition-all hover:bg-primary-500 hover:text-white"
                      key={company.id}
                      onClick={handleSelect(company)}
                      onKeyDown={handleKeyDownSelect(company)}
                      role="option"
                      aria-selected={false}
                      tabIndex={0}
                    >
                      <div className="text-sm">{company.name}</div>
                      <Icon className="text-white" size={16} type="arrowRight" />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </Dialog.Description>
        <Dialog.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  )
}
