export type NullableStringType = string | null

export type FeatureType = 'Survey' | 'Declaration'

export interface ICompany {
  id: string
  zohoCrmAccountId: number
  name: NullableStringType
  type: NullableStringType
  description: NullableStringType
  website: NullableStringType
  companyLogoUrl: NullableStringType
  country: NullableStringType
  state: NullableStringType
  city: NullableStringType
  postCode: NullableStringType
  street: NullableStringType
  registrationNumber: NullableStringType
  isPublic: boolean
  ssoConnection: NullableStringType
  sharePointPath: NullableStringType
  companySize: NullableStringType
}

export enum SubmissionTypeEnum {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum SurveyTypeEnum {
  COCOA = 'Cocoa',
  COFFEE = 'Coffee',
  CONTACTCONFIRMATION = 'ContactConfirmation',
  OFFLINE_DECLARATION = 'OfflineDeclaration',
  PALMOIL = 'Palm Oil',
  SOYA = 'Soya',
}

export enum SurveyStatusEnum {
  Accepted = 'Accepted',
  Completed = 'Completed',
  Created = 'Created',
  Excluded = 'Excluded',
  ExclusionRequested = 'Exclusion Requested',
  InProgress = 'In Progress',
  InReview = 'In Review',
  New = 'New',
  Rejected = 'Rejected',
  Sent = 'Sent', // ready to validate
}

export type SurveyParamsType = {
  surveyId: string
  type: SurveyTypeEnum
  submissionType: SubmissionTypeEnum
}

export enum DeclarationEnum {
  Feed = 'Feed',
  NoFeed = 'NoFeed',
}

export enum DeclarationQuestionEnum {
  Declaration = 'Declaration',
  Evidence = 'Evidence',
  Origin = 'OriginEvidence'
}

export enum EvidenceQuestionEnum {
  Certificate = 'Certificate',
  Origin = 'Origin',
}

export interface ISurveyFileContent {
  type: string
  name: string
  content: string
}

export enum AuthErrorTypesEnum {
  no_token = 'no_token',
  login_required = 'login_required',
  consent_required = 'consent_required',
  invalid_grant = 'invalid_grant',
  unauthorized = 'unauthorized',
  timeout = 'timeout',
  invalid_request = 'invalid_request',
  invalid_scope = 'invalid_scope',
  server_error = 'server_error',
  interaction_required = 'interaction_required',
  access_denied = 'access_denied',
}

export enum DashboardCardTypeEnum {
  Validate_customers = 'Validate customers',
  Policy_questions = 'Policy questions',
  Commodity_declarations = 'Commodity declarations',
}

export interface SurveySectionStat {
  completed: number
  total: number
}
