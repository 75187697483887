import React, { createContext, useEffect, useMemo, useState } from 'react'

import { jwtDecode } from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'
import { USER_METADATA } from 'constants/'
import { useToken } from 'hooks/useToken'
import { isFeatureEnable } from 'utils/isFeatureEnabled'
import { FEATURE_FLAG_KEYS } from 'constants/featureFlagKeys'
import { useByPassClosure } from 'hooks/useByPassClosure'

interface IUserContextProps {
  email: string
  fullName: string
  is3KeelUser?: boolean
  userCompanyId: string
  isPermissionsLoading: boolean
  isSurveyWindowClosed: boolean
}

const isSurveyWindowClosed = isFeatureEnable(FEATURE_FLAG_KEYS.SURVEYS_CLOSE)
const defaultContextProps = {
  email: '',
  fullName: '',
  is3KeelUser: undefined,
  userCompanyId: '',
  isPermissionsLoading: true,
  isSurveyWindowClosed,
}

export const UserContext = createContext<IUserContextProps>(defaultContextProps)

interface DecodedAccessToken {
  permissions?: string[]
}

export function UserProvider({ children }) {
  const { user, isLoading: isUserLoading, isAuthenticated } = useAuth0()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [is3KeelUser, setIs3KeelUser] = useState(false)
  const { acquireToken } = useToken()

  const { isByPass } = useByPassClosure()

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await acquireToken() // Assuming acquireToken is available in this context
        const decoded = jwtDecode<DecodedAccessToken>(token)
        const permissions = decoded.permissions || []
        setIs3KeelUser(permissions.includes('manage:commodity_dashboard'))
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching user data:', error)
        setIsLoading(false)
      }
    }
    fetchPermissions()
  }, [user?.sub, acquireToken])

  const fullName = useMemo(() => {
    const name = user?.name || user?.email || ''
    if (name.split(' ').length > 1) {
      return name
    }
    return name.split('@')[0]
  }, [user?.name, user?.email])

  const userCompanyId = user?.[USER_METADATA].companyId

  const isPermissionsLoading = isLoading || isUserLoading || !isAuthenticated
  const providerValue = useMemo(
    () => ({
      email: user?.email ?? '',
      fullName,
      is3KeelUser,
      userCompanyId,
      isPermissionsLoading,
      isSurveyWindowClosed: isByPass ? false : isSurveyWindowClosed,
    }),
    [fullName, user?.email, is3KeelUser, isByPass, isPermissionsLoading, userCompanyId],
  )

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>
}
