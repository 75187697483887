export const APP_NAV_URLS = {
	ARCHIVED_SURVEYS: '/archived-surveys',
	BASE: '/',
	DASHBOARD: '/dashboard',
	CONTACT_CONFIRMATION: '/contact-confirmation',
	OFFLINE_DECLARATION: '/offline-declaration',
	SURVEY_OPEN_STATUS: '/survey-open-status',
	SURVEY_CLOSE_STATUS: '/survey-close-status',
	SURVEY_WELCOME: '/survey-welcome',
	SURVEYS: '/surveys',
	SURVEY_SUBMISSION: '/surveys/submission-selection/:surveyId/:type/',
	SURVEY: '/surveys/:surveyId',
	ERROR: '/error',
	ADMIN: {
		BASE: '/admin',
		TEMPLATES: '/templates',
		TEMPLATE: '/templates/:type/:submissionType'
	},
}